function contactModul()
{
    'use strict';

    // -------------------------------------------
    this.init = function()
    {
        initForm();
    };


    // -------------------------------------------
    function initForm()
    {
        grecaptcha.ready(function() {
            grecaptcha.execute('6Lflr5YaAAAAAJTl2u_3C4ekFSCIi3IK0m9DlWxb', {action: 'homepage'}).then(function(token) {
                // console.log(token);
                document.getElementById("token").value = token;
            });
        });


        $('#contact_form')[0].reset();

        var formMessages = $('#form-messages');
        var message = $('.contact_msg');
        var forms = $('#contact_form');
        var form_data;

        // Success function
        function done_func(response) {
            message.fadeIn()
            message.html(response);
            setTimeout(function () {
                message.fadeOut();
            }, 10000);
            form.find('input:not([type="submit"]), textarea').val('');
        }

        // fail function
        function fail_func(data) {
            message.fadeIn()
            message.html(data.responseText);
            setTimeout(function () {
                message.fadeOut();
            }, 10000);
        }

        var validation = Array.prototype.filter.call(forms, function(form)
        {
            // check validation
            form.addEventListener('submit', function(event)
            {
                if (form.checkValidity() === false)
                {
                    event.preventDefault();
                    event.stopPropagation();
                }
                else
                {
                    event.preventDefault();

                    var formData = $(form).serialize();

                    $.ajax({
                        type: 'POST',
                        url: $(form).attr('action'),
                        data: formData
                    }).done(function(response)
                    {
                        message.fadeIn()
                        message.html(response);
                        setTimeout(function () {
                            message.fadeOut();
                        }, 10000);
                        form.find('input:not([type="submit"]), textarea').val('');

                    }).fail(function(data)
                    {
                        message.fadeIn()
                        message.html(data.responseText);
                        setTimeout(function () {
                            message.fadeOut();
                        }, 10000);
                    });
                }

                form.classList.add('was-validated');

            }, false);


            // reset validation
            form.addEventListener('reset', function(event)
            {
                form.classList.remove('was-validated');
                $(formMessages).text('');

            }, false);
        });
    }

    // -------------------------------------------
    this.onScroll = function()
    {
        // grecaptcha-badge position
        var footerHeight = $("#footer").height();
        $(".grecaptcha-badge").css("bottom", footerHeight+"px");


        console.log(">> + " + $("#kontakt").isOnScreen());


        // grecaptcha-badge
        if($("#kontakt").isOnScreen() == true)
        {
            $(".grecaptcha-badge").addClass("show");
            $(".grecaptcha-badge").fadeIn();
        }
        else
        {
            $(".grecaptcha-badge").removeClass("show");
            $(".grecaptcha-badge").fadeOut();
        }
    }
    // -------------------------------------------
    this.onResize = function()
    {

    }
    // -------------------------------------------
    $.fn.isOnScreen = function(){

        var win = $(window);

        var viewport = {
            top : win.scrollTop(),
            left : win.scrollLeft()
        };
        viewport.right = viewport.left + win.width();
        viewport.bottom = viewport.top + win.height();

        var bounds = this.offset();
        bounds.right = bounds.left + this.outerWidth();
        bounds.bottom = bounds.top + this.outerHeight();

        return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));

    };
}

module.exports = contactModul;
