
function modalModul()
{
    'use strict';


    // -------------------------------------------
    this.init = function(  )
    {
       // initListener();
    };

    // -------------------------------------------
    function initListener()
    {
        $(".close_button").click(function()
        {
            //$('.modal').modal('hide');
            $('#m_detail').modal('hide');
        });
    }


    // -------------------------------------------
    this.onResize = function()
    {

    }
}

module.exports = modalModul;
