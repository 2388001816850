import 'bootstrap'
import '../sass/main.scss'

import 'slick-carousel';

import Parallax from 'parallax-scroll';

var ScrollMagic = require('scrollmagic/scrollmagic/minified/ScrollMagic.min');


import Navigation from './navigation';
import Contact from './contact';
import Modal from './modal';




(function (  )
{
    'use strict';

    $(document).ready(function()
    {
        // preloader
        $('#preloader').fadeOut(1000,function(){ });

        // moduls
        var navigationModul = new Navigation();
        navigationModul.init();

        var contactModul = new Contact();
        contactModul.init();

        var modalModul = new Modal();
        modalModul.init();

        //$('#m_imprint').modal('show');


        //$("html,body").scrollTop(0);

        const parallax = new Parallax('.js-parallax', {
            speed: 0.2, // Anything over 0.5 looks silly
        });

        parallax.animate();

        initScrollMagic();



        $(".button_deactive").click(function(e)
        {
            e.preventDefault();
        });


        $('.projekt_images').slick({
            dots: false,
            arrows: true,
            nextArrow: ".projekte_button_next",
            prevArrow: ".projekte_button_prev"
        });






        // -------------------------------------------
        function initScrollMagic()
        {
            var controller1 = new ScrollMagic.Controller();
            $('.animateToTop1, .animateFadein, .animateFromLeft, .animateFromRight, .animateFromCenter').each(function(){

                var scene = new ScrollMagic.Scene({
                    triggerElement: this,//.children[0],

                    triggerHook:0.9,
                    reverse:true
                })
                    .setClassToggle(this,"animate")
                    .addTo(controller1);
            });

            var controller2 = new ScrollMagic.Controller();
            $('.animateToTop2').each(function(){

                var scene = new ScrollMagic.Scene({
                    triggerElement: this,//.children[0],

                    triggerHook:0.85,
                    reverse:true
                })
                    .setClassToggle(this,"animate")
                    .addTo(controller1);
            });

        }





        // -------------------------------------------
        $(window).on('scroll', function()
        {
            contactModul.onScroll();
            navigationModul.onScroll();

            //alert();
            var scrollTop =  $(window).scrollTop();

            if(scrollTop > 0)
            {
                $(".navbar").addClass("scrolled");

            }
            else
            {
                $(".navbar").removeClass("scrolled");
            }

        });
        $(window).scroll();


        // -------------------------------------------
        $(window).on('resize', function()
        {


        });
        $(window).resize();
    });
})();