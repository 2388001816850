function navigationModul()
{
    'use strict';

    // -------------------------------------------
    this.init = function()
    {
        initListener();
    };


    // -------------------------------------------
    function initListener()
    {

        $(".nav-link").click(function(event)
        {
            $('html,body').animate({scrollTop:$(this.hash).offset().top - 60}, 850);

            closeNavi()
        });

    }

    function closeNavi()
    {
        $("#main_nav").removeClass("show");

        $(".navbar-toggler").addClass("collapsed");
    }

    this.onScroll = function()
    {
        closeNavi();

    };


}module.exports = navigationModul;
